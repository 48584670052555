import styled, { css } from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { FC, useEffect } from 'react';
import Switch from '@components/molecules/Switch/Switch';
import { graphql, useStaticQuery } from 'gatsby';

const StyledWrapper = styled.div`
  @media print {
    display: flex;
    flex-direction: column;
    order: 99999999;
  }
`;

interface StyledProps {
  $isActive: boolean;
}

const StyledGatsbyImagesWrapper = styled.div`
  margin: 40px auto;
  width: 100%;
  max-width: 620px;
  max-height: 620px;
  height: 100vw;
  position: relative;

  @media (min-width: 1025px) {
    width: 45vw;
    height: 30vw;
    max-width: 885px;
    max-height: 885px;
  }

  @media (max-width: 365px) {
    margin: 10px auto;
  }

  @media print {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: unset;
    max-height: unset;
    max-width: unset;
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)<StyledProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  ${({ $isActive }) =>
    $isActive &&
    css`
      opacity: 1;
    `}

  @media print {
    opacity: 1 !important;
    position: relative;
    height: auto;
  }
`;

const VisAnd2DView: FC<Props> = ({
  currentFloor,
  setCurrentFloor,
  currentView,
  setCurrentView,
  houseQuery,
  ...props
}) => {
  const data = useStaticQuery(query);

  const rightFirstFloorHouses =
    houseQuery === '1' ||
    houseQuery === '5' ||
    houseQuery === '9' ||
    houseQuery === '13' ||
    houseQuery === '17' ||
    houseQuery === '21';

  const rightSecondFloorHouses =
    houseQuery === '2' ||
    houseQuery === '6' ||
    houseQuery === '10' ||
    houseQuery === '14' ||
    houseQuery === '18' ||
    houseQuery === '22';

  const leftFirstFloorHouses =
    houseQuery === '3' ||
    houseQuery === '7' ||
    houseQuery === '11' ||
    houseQuery === '15' ||
    houseQuery === '19' ||
    houseQuery === '23';

  const leftSecondFloorHouses =
    houseQuery === '4' ||
    houseQuery === '8' ||
    houseQuery === '12' ||
    houseQuery === '16' ||
    houseQuery === '20' ||
    houseQuery === '24';

  useEffect(() => {
    typeof window !== 'undefined' &&
      window.addEventListener('beforeprint', (event) => {});
  }, []);

  return (
    <StyledWrapper {...props}>
      <Switch
        firstText="Wizualizacja"
        secondText="Rzut 2D"
        currentValue={currentView}
        setCurrentValue={setCurrentView}
        id="visType"
      />

      <StyledGatsbyImagesWrapper>
        <StyledGatsbyImage
          image={
            rightFirstFloorHouses
              ? getImage(data.View3DFirstFloorRight)
              : rightSecondFloorHouses
              ? getImage(data.View3DSecondFloorRight)
              : leftFirstFloorHouses
              ? getImage(data.View3DFirstFloorLeft)
              : leftSecondFloorHouses
              ? getImage(data.View3DSecondFloorLeft)
              : houseQuery === '25'
              ? getImage(data.View3DLastFirstFloor)
              : getImage(data.View3DLastSecondFloor)
          }
          alt=""
          $isActive={currentFloor === 0 && currentView == 0}
          className="img"
          objectFit="contain"
          loading="eager"
        />

        {/* <StyledGatsbyImage
          image={
            rightHouses
              ? getImage(data.View3DSecondFloorRight)
              : getImage(data.View3DSecondFloorLeft)
          }
          alt=""
          $isActive={currentFloor === 1 && currentView == 0}
          className="img"
          objectFit="contain"
          loading="eager"
        /> */}

        <StyledGatsbyImage
          image={
            rightFirstFloorHouses
              ? getImage(data.View2DFirstFloorRight)
              : rightSecondFloorHouses
              ? getImage(data.View2DSecondFloorRight)
              : leftFirstFloorHouses
              ? getImage(data.View2DFirstFloorLeft)
              : leftSecondFloorHouses
              ? getImage(data.View2DSecondFloorLeft)
              : houseQuery === '25'
              ? getImage(data.View2DFirstFloorRight)
              : getImage(data.View2DSecondFloorRight)
          }
          alt=""
          $isActive={currentFloor === 0 && currentView == 1}
          className="img"
          objectFit="contain"
          loading="eager"
        />

        <StyledGatsbyImage
          image={getImage(data.View2DSecondFloor)}
          alt=""
          $isActive={currentFloor === 1 && currentView == 1}
          className="img"
          objectFit="contain"
          loading="eager"
        />
      </StyledGatsbyImagesWrapper>

      {/* <Switch
        firstText="Parter"
        secondText="Poddasze"
        currentValue={currentFloor}
        setCurrentValue={setCurrentFloor}
        id="visType2"
      /> */}
    </StyledWrapper>
  );
};

interface Props {
  currentFloor: 0 | 1;
  setCurrentFloor: React.Dispatch<React.SetStateAction<0 | 1>>;
  currentView: 0 | 1;
  setCurrentView: React.Dispatch<React.SetStateAction<0 | 1>>;
  houseQuery: string;
}

const query = graphql`
  {
    View2DFirstFloorRight: file(name: { eq: "2d_firstfloor_right" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
          layout: FULL_WIDTH
        )
      }
    }

    View2DFirstFloorLeft: file(name: { eq: "2d_firstfloor_left" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
          layout: FULL_WIDTH
        )
      }
    }

    View2DSecondFloorLeft: file(name: { eq: "2d_secondfloor_left" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
          layout: FULL_WIDTH
        )
      }
    }

    View2DSecondFloorRight: file(name: { eq: "2d_secondfloor_right" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
          layout: FULL_WIDTH
        )
      }
    }

    View3DFirstFloorLeft: file(name: { eq: "3dview_first-floor-left" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
          layout: FULL_WIDTH
        )
      }
    }

    View3DFirstFloorRight: file(name: { eq: "3dview_first-floor-right" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
          layout: FULL_WIDTH
        )
      }
    }

    View3DSecondFloorLeft: file(name: { eq: "3dview_second-floor-left" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
          layout: FULL_WIDTH
        )
      }
    }

    View3DSecondFloorRight: file(name: { eq: "3dview_second-floor-right" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
          layout: FULL_WIDTH
        )
      }
    }

    View3DLastFirstFloor: file(name: { eq: "BUD_C_PARTER" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
          layout: FULL_WIDTH
        )
      }
    }

    View3DLastSecondFloor: file(name: { eq: "BUD_C_PIETRO" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          placeholder: BLURRED
          formats: [WEBP, JPG]
          layout: FULL_WIDTH
        )
      }
    }
  }
`;

export default VisAnd2DView;
