import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { BasicTemplate } from '@templates/BasicTemplate';
import Button from '@components/atoms/Button/Button';
import Table from '@components/molecules/Table/Table';
import Headline from '@components/atoms/Headline/Headline';
import RendProInteractiveVis from '@components/molecules/RendProInteractiveVis/RendProInteractiveVis';
import Contact from '@components/organisms/Contact/Contact';
import VisAnd2DView from '@components/organisms/VisAnd2DView/VisAnd2DView';
import Link from 'gatsby-link';
import { graphql } from 'gatsby';
import { House, Room } from '@interfaces/house';
import { formatPrice } from '@utils/formatPrice';
import { Availability } from '@enums/availability';

interface StyledProps {
  $available?: boolean;
  $unavailable?: boolean;
  $reserved?: boolean;
  $isActive?: boolean;
}

const StyledBasicTemplate = styled(BasicTemplate)`
  padding-top: 65px;

  @media (min-width: 1025px) {
    padding-top: 0;
  }
`;

const centerStyles = css`
  width: 90%;
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1025px) {
    max-width: 1700px;
  }
`;

const StyledInnerWrapper = styled.div`
  ${centerStyles};
  margin-top: 50px;

  @media (min-width: 1025px) {
    > div:last-of-type {
      margin-top: 80px;
    }
  }

  @media (min-width: 1280px) {
    display: flex;
    justify-content: space-between;

    > div:first-of-type {
      width: 45%;
    }

    > div:last-of-type {
      width: 45%;
      margin-top: 20px;
    }
  }

  @media print {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;

    > div {
      display: flex;
      flex-direction: column;
      width: 100% !important;

      &:last-of-type {
        margin-top: 20px;
      }
    }
  }
`;

const StyledSection = styled.section`
  margin-top: 45px;

  @media (min-width: 470px) {
    margin-top: 80px;

    :first-of-type {
      margin-top: 45px;
    }
  }

  @media (min-width: 1025px) {
    margin-top: 200px;
  }

  @media print {
    margin-top: 0 !important;
  }
`;

const StyledHeadline = styled(Headline)`
  ${centerStyles}
  margin-bottom: 45px;

  @media print {
    display: none;
  }

  @media (min-width: 520px) {
    margin-bottom: 80px;
  }

  @media (min-width: 1025px) {
    margin-bottom: 120px;
  }
`;

const StyledButtonsWrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${centerStyles};

  @media (min-width: 1025px) {
    margin-top: 45px;
    padding-bottom: 40px;
    border-bottom: 1px solid ${({ theme }) => theme.gray200};
  }

  @media (max-width: 1024px) {
    margin-bottom: -20px;
  }

  @media (max-width: 365px) {
    flex-direction: column;
    justify-content: center;
  }

  @media print {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  @media (max-width: 1024px) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
`;

const StyledStatus = styled.p<StyledProps>`
  font-size: ${({ theme }) => theme.bodyXXL};
  font-weight: ${({ theme }) => theme.bold};
  margin-top: 35px;
  margin-bottom: 0;

  @media (min-width: 1025px) {
    font-size: ${({ theme }) => theme.headingS};
  }

  ${({ $available }) =>
    $available &&
    css`
      span {
        color: ${({ theme }) => theme.available};
      }
    `}

  ${({ $unavailable }) =>
    $unavailable &&
    css`
      span {
        color: ${({ theme }) => theme.unavailable};
      }
    `}

  ${({ $reserved }) =>
    $reserved &&
    css`
      span {
        color: ${({ theme }) => theme.reserved};
      }
    `}

  ${centerStyles}
`;

const StyledHouseName = styled.h1`
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary};
  font-weight: ${({ theme }) => theme.medium};
  font-size: ${({ theme }) => theme.bodyS};
  margin-top: 10px;
  position: relative;

  ::before {
    position: absolute;
    content: '';
    width: 35px;
    height: 1px;
    background: ${({ theme }) => theme.gray200};
    bottom: -13px;
    left: 0;
  }

  @media (min-width: 1025px) {
    font-size: ${({ theme }) => theme.bodyXXL};
    margin-top: 15px;
  }

  ${centerStyles}
`;

const StyledTable = styled(Table)<StyledProps>`
  margin-top: 15px;

  :last-of-type {
    margin-top: 25px;
  }

  @media (min-width: 1025px) {
    margin-top: 20px;
  }

  ${({ $isActive }) =>
    $isActive === false &&
    css`
      display: none;
    `}

  @media print {
    display: block !important;
    break-inside: avoid;

    :last-of-type {
      margin-top: 20px;
    }
  }

  ${centerStyles}
`;

const StyledPriceWrapper = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;

  span {
    font-size: ${({ theme }) => theme.bodyS};
  }

  p {
    margin: 10px 0 0 0;
    line-height: 1;
    font-size: ${({ theme }) => theme.headingS};
    color: ${({ theme }) => theme.primary};
    font-weight: ${({ theme }) => theme.bold};
  }

  @media (min-width: 1025px) {
    margin-top: 40px;

    span {
      font-size: ${({ theme }) => theme.bodyXXL};
    }

    p {
      font-size: ${({ theme }) => theme.headingM};
      margin-top: 20px;
    }
  }

  @media print {
    order: 99;
  }

  ${centerStyles}
`;

const StyledInteractiveVisualization = styled(RendProInteractiveVis)`
  @media (max-width: 1024px) {
    margin: 60px auto 0 auto;
    max-width: 620px;
  }

  @media (min-width: 1025px) {
    margin-top: 140px;
  }
`;

const StyledTableHeadline = styled.h3`
  display: none;
  font-size: ${({ theme }) => theme.bodyXXL};
  width: 90%;
  margin: 20px auto 0 auto;

  @media print {
    display: block;
  }
`;

const Dom1: FC<Props> = ({ data }) => {
  const {
    number,
    availability,
    area,
    deliveryDate,
    parcelArea,
    price,
    firstFloor,
    roomNumber,
    secondFloor,
    garage,
  } = data.datoCmsHouse;
  const [width, setWidth] = useState<number>(null);
  const [currentFloor, setCurrentFloor] = useState<0 | 1>(0);
  const [currentView, setCurrentView] = useState<0 | 1>(0);

  const mapFloor = (floor: Room[]) =>
    floor
      .sort((prev, curr) => (prev.roomNumber > curr.roomNumber ? 1 : -1))
      .map(({ name, area }) => ({
        name,
        value: `${area} m2`,
      }));

  const handlePrintClick = () =>
    typeof window !== 'undefined' && window.print();

  return (
    <StyledBasicTemplate
      setWidth={setWidth}
      withoutNavigation={width >= 1025}
      title={`Mieszkanie ${number}`}
    >
      <StyledSection>
        <StyledButtonsWrapper>
          {/* @ts-ignore */}
          <StyledButton
            icon="angle-left-bold"
            iconPosition="left"
            iconSize={width <= 1024 ? '2.5rem' : '3rem'}
            forwardedAs={Link as any}
            to="/#offer"
            state={{
              prevPath:
                typeof window !== 'undefined' && window.location.pathname,
            }}
          >
            Powrót
          </StyledButton>
          <StyledButton icon="download" secondary onClick={handlePrintClick}>
            Pobierz PDF
          </StyledButton>
        </StyledButtonsWrapper>

        <StyledInnerWrapper>
          <VisAnd2DView
            houseQuery={number}
            currentFloor={currentFloor}
            setCurrentFloor={setCurrentFloor}
            currentView={currentView}
            setCurrentView={setCurrentView}
            data-aos="fade-up"
          />

          <div data-aos="fade-up" data-aos-delay="500">
            <StyledStatus
              $available={availability === Availability.Available}
              $reserved={availability === Availability.Reserved}
              $unavailable={availability === Availability.Unavailable}
            >
              Status: <span>{availability.toLocaleLowerCase()}</span>
            </StyledStatus>

            <StyledHouseName>Mieszkanie nr. {number}</StyledHouseName>

            <StyledTable
              items={[
                {
                  name: 'Powierzchnia:',
                  value: `${area} m2`,
                },
                {
                  name: 'Powierzchnia działki:',
                  value: `${parcelArea} m2`,
                },
                {
                  name: 'Ilość pokoi:',
                  value: roomNumber.toString(),
                },
                {
                  name: 'Miejsce postojowe:',
                  value: garage,
                },
                {
                  name: 'Termin oddania:',
                  value: deliveryDate,
                },
              ]}
            />

            <StyledPriceWrapper>
              <span>Cena: </span>
              <p>{formatPrice(price)}</p>
            </StyledPriceWrapper>

            <StyledTableHeadline>Pomieszczenia:</StyledTableHeadline>
            <StyledTable
              secondary
              items={mapFloor(firstFloor)}
              $isActive={currentFloor === 0}
            />

            <StyledTableHeadline>Poddasze</StyledTableHeadline>

            {/* <StyledTable
              secondary
              items={mapFloor(secondFloor)}
              $isActive={currentFloor === 1}
            /> */}
          </div>
        </StyledInnerWrapper>
      </StyledSection>

      <StyledSection>
        <StyledHeadline
          behindText="Oferta"
          behindTextSize="11rem"
          secondaryText="Oferta domów"
          id="offer"
        >
          Wybierz interesujący Cię budynek, aby zobaczyć szczegóły oferty
        </StyledHeadline>

        <StyledInteractiveVisualization data-aos="fade-up" />
      </StyledSection>
      <Contact />
    </StyledBasicTemplate>
  );
};

interface Props {
  data: {
    datoCmsHouse: House;
  };
}

export const query = graphql`
  query($number: String!) {
    datoCmsHouse(number: { eq: $number }) {
      number
      area
      parcelArea
      price
      deliveryDate(formatString: "DD MMMM YYYY", locale: "PL")
      availability
      garage
      roomNumber
      firstFloor {
        roomNumber
        name
        area
      }
    }
  }
`;

export default Dom1;
